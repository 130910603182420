.homeHead {
    /* background: red; */
    width: 100%;
    height: 44em;

    /* background-image: url('../../asset/img/servicebg.jpg'); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/servicebg.jpg');

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #000000;
}

.homesubh1 {
    /* background: red; */
    display: flex;
    width: 100%;
    /* height: 25%; */
    height: 8.5em;
    padding-left: 60px;
    padding-right: 60px;
}

.homeheadlogo {
    margin-right: 5em;
}

.mainhome {
    background-color: #F5F5F5;
    width: 100%;
    height: 50em;
    display: flex;
}

.homehello {
    /* background: red; */
    width: 78%;
    height: 90%;
}

.helo {
    color: rgb(20, 139, 169);
    /* font-weight: 700; */
    font-size: 34px;
    line-height: 38px;
    padding-top: 8px;
    font-family: 'Poppins-Bold';

}

.title1 {
    color: rgb(255, 255, 255);
    /* font-weight: 700; */
    font-size: 60px;
    text-transform: uppercase;
    padding-top: 22px;
    font-family: 'Poppins-Bold';

}

.title2 {
    color: rgb(255, 255, 255);
    /* font-weight: 700; */
    font-size: 60px;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 22px;
    font-family: 'Poppins-Bold';

}

.sucss {
    color: rgb(255, 255, 255);
    /* font-weight: 500; */
    font-size: 27px;
    border-color: rgb(20, 139, 169);
    border-width: 0px 0px 0px 3px;
    padding-left: 25px;
    border-style: solid;
    font-family: 'Poppins-Bold';
}

.maincardhome1 {
    width: 78%;
    height: 68%;
    /* background: gray; */
    margin: auto;
    display: flex;
    flex-direction: column;

    margin-top: 7em;

}

.cardbtnhome {
    border-radius: 30px;
    color: white;
    background-color: #118ba9;
    width: 7em;
    height: 31px;
    display: flex;
    font-size: 15px;
    font-weight: 600;
}

.para1scardhome {
    color: #222222;
    /* font-weight: 700; */
    font-size: 25px;
    /* padding-top: 15px; */
    font-family: 'Poppins-Bold';
}

.para2scardhome {
    font-size: 17px;
    line-height: 28px;
    color: gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* line-clamp: 2; */
    -webkit-box-orient: vertical;
    text-align: center;
    padding-top: 7px;
    font-family: 'Poppins-Regular';
}

.scardhome {
    flex: 1;
    background: white;
    border: 15px solid whitesmoke;
    border-radius: 13px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3em;

}

.scard1home {
    flex: 1;
    padding-top: 5px;
}

.mainhom1 {
    background-color: #F5F5F5;
    width: 100%;
    height: 28em;
    display: flex;
}

.mainhom2 {
    background-color: #F5F5F5;
    width: 100%;
    height: 25em;
    display: flex;
    padding-top: 5px;
}

.mainhom2sub {
    /* background: red;  */
    width: 78%;
    height: 90%;
    margin: auto;
}

.keptxt1 {
    /* font-weight: bold; */
    color: #148ba9;
    margin-bottom: 20px;
    font-family: 'Poppins-Bold';

}

.keptxt2 {
    /* font-weight: bold; */
    color: #222222;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Poppins-Bold';

}

.keptxt3 {
    margin-top: 10px;
    font-size: 17px;
    color: gray;
    font-family: 'Poppins-Regular';
}

.keptxt4 {
    font-size: 17px;
    color: gray;
    font-family: 'Poppins-Regular';
}

.homservicesec {
    width: 100%;
    height: 20.5em;
    display: flex;
    flex-direction: column;
}

.homprojectsec {
    width: 100%;
    height: 25em;
    display: flex;
    flex-direction: column;
}

.homservicesec1 {
    width: 50%;
    height: 60%;
    /* background: red; */
    margin: auto;
    text-align: center;
}

.homprosec1 {
    width: 50%;
    height: 50%;
    /* background: red; */
    margin: auto;
    text-align: center;
}

.vl {
    border-left: 3px solid #148ba9;
    height: 60px;
}

.linesp {
    display: flex;
    justify-content: center;
}

.serhomeline {
    /* font-weight: bold; */
    margin-bottom: 13px;
    font-size: 50px;
    margin-top: 35px;
    font-family: 'Poppins-Bold';

}

.serhomeline1 {
    /* margin-top: 10px; */
    font-size: 18px;
    color: gray;
    font-family: 'Poppins-Regular';

}

.homecardsec {
    width: 100%;
    height: 11.4em;
    /* background-color: red; */
    display: flex;
}

.homecard2sec {
    width: 100%;
    height: 15em;
    /* background-color: red; */
    display: flex;
    padding-top: 1.5em;

}

.homecardsec1 {
    height: 100%;
    width: 80%;
    /* background-color: rgb(13, 178, 71); */
    margin: auto;

}


.homewebsir {
    text-align: center;
    font-size: 15px;
    padding-top: 1.5em;
    /* font-weight: 700; */
    font-family: 'Poppins-Bold';

}

.homecardsec2 {
    border: 1px solid;
    border-radius: 7px;
    height: 150px;
    text-align: center;
}

.homecardimg {
    padding-top: 2.6em;

}

.homefree {
    width: 100%;
    height: 16em;
    background-color: #F5F5F5;
    display: flex;

}

.homefreeSub {
    width: 76%;
    /* height: 100%; */
    /* background-color: red; */
    margin: auto;
    display: flex;
    align-items: center;
}

.homhowsec {
    width: 100%;
    height: 25em;
    display: flex;
    flex-direction: column;
}

.homhowsec1 {
    width: 50%;
    height: 52%;
    /* background: red; */
    margin: auto;
    text-align: center;
}

.homefreefoot {
    width: 100%;
    height: 17.5em;
    background-color: #151515;
    display: flex;
    color: white;
}

.homeinfo {
    color: white;
    font-size: 20px;
    line-height: 10px;
}


.homebtnonline {
    border-width: 3px;
    border: 2px solid rgba(255, 255, 255, 0.3);

    width: 240px;
    color: #ffffff;
    border-radius: 100px;
    font-size: 17px;
    /* background-color: #118ba9; */
    display: flex;
    justify-content: center;
    /* font-weight: 700; */
    height: 3em;
    padding: 12px;
    font-family: 'Poppins-Bold';

}

.homefreeSubfoot {
    width: 78%;
    /* height: 100%; */
    /* background-color: red; */
    margin: auto;
    display: flex;
    align-items: center;
}

.homecardseccount {
    width: 100%;
    padding-bottom: 5em;
    /* background-color: red; */
    display: flex;
}

.homecardseccount01 {
    width: 100%;
    padding-bottom: 2em;
    /* background-color: red; */
    display: flex;
}

.homecardseccount1 {
    height: 100%;
    width: 78%;
    /* background-color: rgb(13, 178, 71); */
    margin: auto;

}

.homecardseccount2 {
    border: 3px solid rgba(0, 0, 0, 0.12);
    /* border-radius: 7px; */
    height: 34em;
    /* text-align: center; */
    padding-top: 8%;
    padding-right: 10%;
    padding-bottom: 12%;
    padding-left: 10%;
    /* background-color: #148BA9; */
}

.homecardseccount04 {
    border: 3px solid rgba(0, 0, 0, 0.12);
    /* border-radius: 7px; */
    height: 34em;
    /* text-align: center; */
    padding-top: 8%;
    padding-right: 10%;
    padding-bottom: 12%;
    padding-left: 10%;
    background-color: #148BA9;
}

.intetext {
    /* font-weight: bold; */
    color: #148ba9;
    font-size: 50px;
    line-height: 60px;
    font-family: 'Poppins-Bold';

}


.planing {
    /* font-weight: 700; */
    font-size: 21px;
    padding-top: 2px;
    font-family: 'Poppins-Bold';

}

.planing04 {
    /* font-weight: 700; */
    font-size: 21px;
    padding-top: 2px;
    color: white;
    font-family: 'Poppins-Bold';


}

.para02 {
    text-align: justify;
    color: gray;
    padding-top: 4px;
    font-size: 17px;
    font-family: 'Poppins-Regular';

}

.para04 {
    text-align: justify;
    color: #d4d2d2;
    padding-top: 4px;
    font-size: 17px;
    font-family: 'Poppins-Regular';

}

.intetext04 {
    /* font-weight: bold; */
    font-family: 'Poppins-Bold';
    font-size: 50px;
    line-height: 60px;
    color: rgba(255, 255, 255, 0.5);

}

.carbgimg {
    background-image: url('../../asset/img/cardbgimg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-left: 10%;
    padding-right: 10%; */
    width: 100%;
    height: 100%;
}

.homcorsec {
    width: 100%;
    height: 24em;
    /* background-color: gray; */
}

.curimgc {
    width: 113%;
    height: 22em !important;
}

.carousel-indicators {
    bottom: -45px;

}

.carousel-indicators .active {
    background-color: #148BA9;
    border: 1px solid #148BA9;
    margin: 0px 5px 0px 5px;

}

.carousel-indicators li {
    border: 2px solid #148BA9;
    margin: 0px 5px 0px 5px;

}

.viewsec {
    width: 100%;
    /* background: red; */
    height: 12em;
    display: flex;
    justify-content: center;
    padding-top: 4em;
}

.newcor {
    /* width: 303.84px; */

}

.mobsidemew {
    /* display: flex; */
    display: none;
    width: 100%;
    height: 100%;
    justify-content: end;

}

.mobsidemew1 {
    cursor: pointer;
    color: white;
    font-size: 2em;
    margin-top: 1em;
    background: #148ba9;
    width: 43px;
    height: 44px;
    text-align: center;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    /* left: 0; */
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 5px 8px 5px 13px;
    text-decoration: none;
    font-size: 17px;
    color: black;
    display: block;
    transition: 0.3s;
    font-weight: 600;

}

/*   
  .sidenav a:hover {
    color: #f1f1f1;
  } */

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 15px;
    }
}

@media screen and (max-width: 1000px) {
    .mobsidemew {
        display: flex;
    }

    .navtab {
        display: none;
    }
}

@media only screen and (min-width: 990px) and (max-width: 1380px) {

    .homecardseccount2,
    .homecardseccount04 {
        height: 45em;

    }

}

/* @media screen and (max-width: 1380px) {
    .homecardseccount2, .homecardseccount04 {
        height: 45em;

    }
} */

@media screen and (max-width: 1432px) {
    .curimgc{
        width: 120%;
    }
}
@media screen and (max-width: 990px) {
    .homecardseccount1 {
        width: 95%;
    }

    .bornewrec {
        padding: 2em;
    }

    .homecardseccount04 {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .homecardseccount2 {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .homecardseccount01 {
        padding-bottom: 0;
    }

    .imgnhomenew {
        height: 34em;
    }

    .homefreeSubfoot {
        flex-direction: column;
        padding: 5em;
    }

    .homefreefoot {
        height: unset !important;

    }

    .homefreenew {
        padding: 3em;
    }

    .homeheadlogo {
        margin-right: unset !important;
    }

    .hide1 {
        display: none;
    }
}

.homemainingbg{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/potmainhead.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #000000;

}