.tabport {
    width: 100%;
    height: 11em;
    /* background-color: red; */
    display: flex;
}

.tabsub {
    width: 25%;
    /* height: 100%; */
    /* background: green; */
    margin: auto;
}

.cardbtnport1 {
    border-radius: 30px;
    /* color: white;
    background-color: #118ba9; */
    width: 7em;
    height: 31px;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    cursor: pointer;
}

.photosec {
    width: 100%;
    /* height: 31em; */
    /* background: red; */
    padding-bottom: 5em;

}

.readTextport1 {
    /* font-weight: bold; */
    font-family: 'Poppins-Bold';
    margin: auto;
    font-size: 14px;

}

.poert1imgc {
    width: 100%;
    height: 27em;

    /* background-image: url('../../asset/img/carousel/carouselimg6.jpg'); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 0px;
    cursor: pointer;

}

.portmainvew1 {
    /* background: blue; */
    margin: 0;
    padding-left: 14px;
    padding-right: 14px;
}

.portshow {
    background: #000000ab;
    height: 100%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.expincon {
    width: 53px;
    height: 53px;
    border-radius: 50px;
    background: #808080a8;
    display: flex;
}


.exptes {
    color: white;
    padding-top: 10px;
    font-size: 35px;
    /* font-weight: bold; */
    text-align: center;
    font-family: 'Poppins-Bold';

}


.expin1 {
    margin: auto;
    /* font-weight: bold; */
    font-size: 22px;
    color: white;
}

.poert1imgc:hover .portshow {
    display: flex;
}

.cusmodel1 {
    display: flex;
    width: 100%;
    height: 100%;
}

.cusmodel2 {
    width: 68%;
    /* background: red; */
    height: 80%;
    margin: auto;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.closemodel {
    font-size: 2em;
    padding-right: 7px !important;
}

.portfomainingbg{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/potmainhead.jpg');

}