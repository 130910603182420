:root
{
    --theme-color: #148ba9;
}

.contact-us
{
    width: 100%;
    height: 34em;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/servicebg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #000000;
}
.contact-title
{
    color: #fff;
    font-weight: 700;
}
.contact-subtitle
{
    color: #148ba9;
    font-size: 34px;
    font-weight: 800;
}
.sec2-bg
{
    background-color: #f5f5f5;
}
.condum
{
    background-color: #f5f5f5;
    padding: 3em;
}
.sec2-col
{
    background-color: #fff;
    width: 100%;
    /* height: 300px; */
    padding: 30px;
    height:24em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sec2-text1
{
    font-size: 15px;
    /* font-weight: 400; */
    font-family: 'Poppins-Regular';
}
.sec2-text
{
    font-size: 18px;
    /* font-weight: 700; */
    font-family: 'Poppins-Bold';
    margin: 0;
    text-align: center;

}

.sec3-bg
{
    background-color: #fff;
}

.serFooter {
    background: #1a1c20;
    height: 3.7em;
}

.navtab1
{
    padding: 25px 0px 10px 0px;
}

.headtxt
{
    font-size: 50px;
    font-family: 'Poppins-Bold';
}

.headsubtxt
{
    font-size: 16px;
    color: #85868C;
    margin-bottom: 2.5em;
    font-family: 'Poppins-Regular';

}

.newgetin{
    width: 100%;
    /* background: red; */
    text-align: center;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 600px) 
{
    
    .contact-title
    {
        font-size: 34px;
        font-weight: 700;
    }

    .contact-subtitle
    {
        font-size: 18px;
        font-weight: 700;
    }
    .contact-phno
    {
        font-size: 24px;
        font-weight: 700;
    }
}

.container-fluid{
    padding-left: 10% !important;
    padding-right: 12% !important;
}



@media screen and (max-width: 1400px) {

    .container-fluid{
        padding-left: 2% !important;
        padding-right: 2% !important;
    }

}

.cardbtncont {
    border-radius: 30px;
    color: white;
    background-color: #118ba9;
    width: 10em;
    height: 40px;
    display: flex;
    font-size: 14px;
    /* font-weight: 600; */
    font-family: 'Poppins-Bold';
    cursor: pointer;
}

.formrow1 {
    justify-content: center;

}
.inpform1{
    font-family: 'Poppins-Regular';
    /* padding: 5px; */
}


.conmainingbg{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/contactmainhead.jpg');

}