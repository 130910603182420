.serHead {
    /* background: red; */
    width: 100%;
    height: 34em;

    /* background-image: url('../../asset/img/servicebg.jpg'); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/servicebg.jpg');

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #000000;
}

.subhead1 {
    /* background: red; */
    display: flex;
    width: 100%;
    height: 25%;
    padding-left: 60px;
    padding-right: 60px;
}

.subhead2 {
    /* background: blue; */
    width: 100%;
    height: 75%;

    display: flex;
    /* color: red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subhead11 {
    /* background: green; */
    flex: 1;
    /* justify-content: center; */
    display: flex;
    align-items: center;
}

.subhead12 {
    /* background: red; */
    flex: 2;

}

.ulhead li {
    list-style-type: none;
    color: white !important;
    /* font-weight: 800 !important; */
    font-family: 'Poppins-Bold';
    font-size: 17px;
}

.ulhead {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

}

li {
    list-style-type: none;
    color: '#576CA8'
}

/* ul,
  ol {
    margin: 0;
    padding: 0;
  } */

.navtab {
    /* padding-top: 50px; */
    padding: 25px 5em 10px 6em;

}

.subhead_text1 {
    color: white;
    font-size: 70px;
    line-height: 80px;
    color: #ffffff;
    text-transform: uppercase;

    /* font-weight: 700; */
    font-family: 'Poppins-Bold';

}

.subhead_text2 {
    color: #148ba9;
    font-size: 24px;
    line-height: 34px;
    /* color: #2a8f9e; */
    text-transform: none;

    /* font-weight: 700; */
    font-family: 'Poppins-Bold';

    padding: 5px;

}

.subhead_text3 {
    color: white;
}

.serFooter {
    background: #1a1c20;
    height: 3.7em;
}

.footTxt1 {
    color: white;
    font-family: 'Poppins-Regular';

}

.foot1 {
    padding-top: 1.5em;
    padding-left: 14em;
    font-size: 12px;
}

.foot2 {
    padding-top: 1.5em;
    padding-left: 15em;
    font-size: 12px;
    color: white;
    display: flex;

}

.footTxt2 {
    display: flex;
    font-family: 'Poppins-Regular';

}

.footIcon {
    margin-top: 3.7px;
    margin-right: 5px;
}

.footIconmail {
    margin-right: 5px;
    margin-top: 3.7px;
    margin-left: 2em;
}

.footIconwatt {
    margin-right: 5px;
    margin-top: 3.7px;
    margin-left: 1em;
}

.main {
    background-color: #F5F5F5;
    width: 100%;
    height: 50em;
    display: flex;
}

.maincard {
    width: 76%;
    height: 75%;
    /* background: gray; */
    margin: auto;
    display: flex;
    flex-direction: column;
}

.maincard1 {
    flex: 1;
    /* background: red; */
    display: flex;

}

.maincard2 {
    flex: 1;
    /* background: blue; */
    display: flex;
}

.scard {
    flex: 1;
    background: white;
    border: 8px solid whitesmoke;
    border-radius: 13px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.scard1 {
    flex: 1;

}

.webpara {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.cardbtn {
    border-radius: 30px;
    color: white;
    background-color: #118ba9;
    width: 7em;
    height: 40px;
    display: flex;
    font-size: 14px;
    /* font-weight: 600; */
    font-family: 'Poppins-Regular';

}

.para1scard {
    color: #222222;
    /* font-weight: 700; */
    font-size: 20px;
    text-align: center;
    font-family: 'Poppins-Bold';


}

.para2scard {
    font-size: 16px;
    line-height: 1.6em;
    color: gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* line-clamp: 2; */
    -webkit-box-orient: vertical;
    text-align: center;
    font-family: 'Poppins-Regular';


}

.readText {
    margin: auto;
}

.free {
    width: 100%;
    height: 11em;
    background-color: #F5F5F5;
    display: flex;

}

.freeSub {

    width: 76%;
    height: 100%;
    /* background-color: red; */
    margin: auto;
    display: flex;

}

.freeSub1 {
    flex: 1;
}

.freefont {
    /* font-weight: bold; */
    font-family: 'Poppins-Bold';

}

.info {
    color: #148ba9;
    font-size: 18px;
    line-height: 10px;
    font-family: 'Poppins-Regular';

}

.inocard {
    text-align: center;
    font-family: 'Poppins-Bold';
}

.btnonline {
    width: 240px;
    color: #ffffff;
    border-radius: 100px;
    font-size: 16px;
    background-color: #118ba9;
    display: flex;
    justify-content: center;
    /* font-weight: 700; */
    height: 3em;
    padding: 14px;
    font-family: 'Poppins-Bold';
}

.mailbrn2 {
    margin-left: 5px;
    margin-top: 5px;
}

.mailcard {
    display: flex;
    justify-content: center;
}

.freefont1 {
    font-size: 23px;
    font-family: 'Poppins-Bold';

}

.headimgserv {
    margin-left: 7.5em;

}

.abtdum {
    background-color: #F5F5F5;
}

.homedum11 {
    background-color: #F5F5F5;
    padding: 3em;
}

/* footer */
@media only screen and (max-width: 1260px) {
    .foot2 {
        display: flex;
        padding-left: unset;
        justify-content: center;

    }

    .foot1 {
        padding-left: 2em;

    }

    .maincard {
        width: 95%;

    }

}

@media only screen and (max-width: 990px) {
    /* .tabsub  {
        width: 35% !important;
    } */

    .foot2 {
        display: flex;
        padding-left: unset;
        justify-content: center;
        padding-top: unset;

    }

    .foot1 {
        padding-top: 2em;
        padding-left: unset;
        display: flex;
        justify-content: center;
    }

    .serFooter {
        height: 6em !important;

    }
}

@media only screen and (max-width: 940px) {
    .serFooter {
        height: 6em;
    }

    .maincard1 {
        flex-direction: column;
    }

    .mainhom1 {
        height: unset !important;
        padding-bottom: 3em;
    }

    .scardhome {
        padding-bottom: 4em;
    }

    .para2scardhome {
        padding: 1em;
    }

    .tabsub {
        width: unset !important;
    }

    .maintech {
        height: unset !important;
        padding: 10px;
    }

    .maintechsub1 {
        flex-direction: column;

    }

    .maintec13 {
        padding: 1em 10em 5em 10em;

    }

    .maintechsub2 {
        flex-direction: column;
        padding-top: unset !important;

    }

    .maintechsub11 {
        padding: 1em 10em 5em 10em;

    }
}

@media only screen and (max-width: 700px) {
    .subhead_text1 {
        font-size: 60px;
        text-align: center;
    }

    .subhead_text2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .subhead1 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .subhead11 {
        justify-content: unset;
    }

    .para1scard {
        padding: 10px 0px 5px 0px;
    }

    .homecardsec1 {
        width: 95% !important;

    }
}

@media only screen and (min-width: 940px) and (max-width: 1275px) {

    .tabsub {
        width: 35% !important;

    }

}

@media screen and (max-width: 765px) {

    .indeasec {
        display: none;
    }

    .maintec13 {
        padding: 1em 2em 5em 2em;
    }

}

@media screen and (max-width: 500px) {

    .footTxt1 {
        font-size: 9px;

    }

    .foot2 {
        font-size: 9px;

    }

    .maintechsub {
        width: 100% !important
    }

    .tecq1 {
        font-size: 44px;
    }
}

@media screen and (max-width: 1240px) {

    .homeHead {
        height: unset !important;
    }

    .subhead2 {
        padding-bottom: 5em;
    }

    .maintechsub {
        width: 95%;

    }

    .freeSub {
        width: 90%;

    }

    .mailcard {
        justify-content: end;
    }

    .navtab {
        padding: 25px 0em 10px 0em;
    }

    .headimgserv {
        margin-left: 0
    }

    .maincard {
        width: 95%;
    }
}

@media screen and (max-width: 840px) {
    .main {
        height: unset;
        padding: 2em;

    }

    .homedum11 {
        display: none;
    }

    .maincard1 {
        flex-direction: column;
    }

    .maincard2 {
        flex-direction: column;

    }

    .free {
        padding: 3em;
        height: unset !important;
    }

    .freeSub {
        flex-direction: column;
        text-align: center;

    }

    .mailcard {
        justify-content: center;
        padding-top: 2em;

    }

    .abtdum {
        background-color: transparent;
    }
}

@media screen and (max-width: 500px) {
    .subhead_text1 {
        font-size: 45px !important;
    }
}

@media screen and (max-width: 1200px) {
    .maincardhome1 {
        width: 95% !important;

    }

    .mainhom2sub {
        width: 95% !important;

    }

    .mainhom2 {
        padding-top: 3em !important;
        padding-bottom: 8em;
        height: unset !important;
    }

    .keptxt3,
    .keptxt4 {
        line-height: 1.6em;
    }

}

@media screen and (max-width: 991px) {

    .homecardsec,
    .homecard2sec {
        height: unset !important;
    }

    .homecardsec2 {
        height: 13em;

    }

    .newhomeborder {
        padding: 2em;

    }

    .homecardsec2 {
        padding: 2em;
        height: 17em !important;

    }

    .homecardimg {
        width: 65px !important;

    }

    .homewebsir {
        font-size: 22px !important;

    }
}

@media screen and (max-width: 1150px) {
    .homefreeSubfoot {
        width: 93% !important;

    }
}