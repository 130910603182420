.maintech {
    background-color: white;
    width: 100%;
    height: 36em;
    display: flex;
}

.maintechsub{
    width: 72%;
    height: 90%;
    /* background-color: red; */
    margin: auto;
    display: flex;
    flex-direction: column;
}

.maintechsub1 {
    flex: 1;
    /* background-color: blue; */
    display: flex;
    padding-top: 1.5em;
}

.maintechsub2 {
    flex: 1;
    /* background-color: blue; */
    display: flex;
    padding-top: 5em;
}

.maintechsub11 {
    flex: 1;
    padding: 0 30px 0 30px;
    display: flex;
    flex-direction: column;
}

.maintec12 {
    flex: 1.5;
    /* background-color: rgb(69, 208, 162); */

    display: flex;
    flex-direction: column;
    align-items: center;
}
.maintec13 {
    flex: 1;
    /* background-color: rgb(31, 107, 188); */

    display: flex;
    justify-content: space-around;

}

.parajs {
    color: #333333;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding-top: 1em;

}

.newdevops {
    padding-top: 34px;

}

.techmainingbg{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/tecmainhead.jpg');

}