:root
{
    --theme-color: #148ba9;
}

.about-us
{
    width: 100%;
    height: 34em;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../asset/img/servicebg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #000000;
}

.about-us-content p, .about-us-content ul li
{
    font-size: 16px;
}

.about-title
{
    color: #fff;
    font-weight: 700;
}
.about-subtitle
{
    color: #148ba9;
    font-size: 34px;
    font-weight: 800;
}
.topic-head
{
    color: var(--theme-color);
    /* font-weight: 700; */
    font-family: 'Poppins-Bold';
    font-size: 25px;

}
.para-text
{
    font-size: 24px;
    color: #85868c;
    font-family: 'Poppins-Regular'

}
.bulletins
{
    color: #85868c;
    font-size: 24px;
}
.bulletins li
{
    list-style-type: disc;
    font-family: 'Poppins-Regular';

}
.gr-bg
{
    background-color: #f5f5f5;
}
.email-id
{
    font-size: 24px;
    color: var(--theme-color);
}
.on-form
{
    border-radius: 30px;
    color: #fff;
    font-weight: 800;
    padding: 10px;
    font-size: 20px;
    background-color:#148ba9;
    border-color: #148ba9;
}

.on-form:hover
{
    background-color:#148ba9;
    border-color: #148ba9; 
}


.serFooter {
    background: #1a1c20;
    height: 3.7em;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) 
{
    .about-title
    {
        font-size: 34px;
        font-weight: 700;
    }

    .about-subtitle
    {
        font-size: 18px;
        font-weight: 700;
    }

    .topic-head
    {
        font-size: 20px;
    }

    .para-text
    {
        font-size: 16px;
    }

    .about-us-content .row
    {
        padding: 10px 15px;
    }

    .m-d-flex
    {
        display: flex!important;
    }

    .m-j-c
    {
        justify-content: center!important;
    }

    .mt-m-2
    {
        margin-top: 1rem !important;
    }
    .mb-m-2
    {
        margin-bottom: 1rem !important;
    }
    .mb-m-4
    {
        margin-bottom: 2rem !important;
    }

    .about-l-sec h2,.about-l-sec h3
    {
        font-weight: 700;
    } 
    
}

.aboutnew{

}