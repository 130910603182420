body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto"),
    url("./asset/font/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins"),
    url("./asset/font/Poppins/Poppins-Regular.ttf") format("truetype");
} 

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"),
    url("./asset/font/Poppins/Poppins-Bold.ttf") format("truetype");
} 

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins"),
    url("./asset/font/Poppins/Poppins-Regular.ttf") format("truetype");
} 

/* @font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto"),
    url("./assets/font/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito"),
    url("./assets/font/Nunito/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"),
    url("./assets/font/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins"),
    url("./assets/font/Poppins/Poppins-Regular.ttf") format("truetype");
} */